import React from 'react';

/** Import helpers section **/
import { T } from 'react-translator-component';

/** Import resources section **/
import logo from '../../assets/images/logo.png';
import mailIcon from '../../assets/images/icon_mail.png';
import phoneIcon from '../../assets/images/icon_phone.png';
import youtubeIcon from '../../assets/images/icon_youtube.png';
import faceIcon from '../../assets/images/icon_face.png';

/** Import styles section **/
import './ContactStyles.scss';

const borderStyle = {
  border: '4px solid',
  borderImageSlice: '1',
  borderWidth: '4px',
  borderImageSource: 'linear-gradient(45deg, #f9d800, #a16407,#f9d800, #a16407)'
};

function ContactView({ data, onInputChange, onSubmit }) {
  return (
    <div className="contact">
      <div className="contact_container" style={borderStyle}>
        <div className="contact_container_left">
          <div className="contact_container_left_title">{T('Contacto')}</div>
          <div className="contact_container_left_text">
            {T(
              'Utilice el formulario de contacto si tiene alguna pregunta o solicitud sobre nuestros servicios'
            )}
          </div>
          <a href="mailto:inf@fidtail.org">
            <div className="contact_container_left_item">
              <div className="contact_container_left_icon">
                <img src={mailIcon} alt="mail-icon" />
              </div>
              inf@fidtail.org
            </div>
          </a>
          <a href="tel:+52 14611690867">
            <div className="contact_container_left_item">
              <div className="contact_container_left_icon">
                <img src={phoneIcon} alt="phone-icon" />
              </div>
              +52 14611690867
            </div>
          </a>
          <div className="contact_container_left_social">
            <div
              className="contact_container_left_icon"
              onClick={() =>
                window.open(
                  'https://www.facebook.com/Fidtail-155067595214095',
                  '_blank'
                )
              }
            >
              <img src={faceIcon} alt="facebook-icon" />
            </div>
            <div
              className="contact_container_left_icon"
              onClick={() =>
                window.open(
                  'https://www.youtube.com/channel/UCLvZ6yxikdsJB3qa8u2L4QA',
                  '_blank'
                )
              }
            >
              <img src={youtubeIcon} alt="youtube-icon" />
            </div>
          </div>
        </div>
        <div className="contact_container_right">
          <form onSubmit={onSubmit}>
            <input
              type="text"
              name="name"
              placeholder={T('Nombre')}
              value={data?.name}
              onChange={onInputChange}
            />
            <input
              type="text"
              name="email"
              placeholder={T('Correo')}
              value={data?.email}
              onChange={onInputChange}
            />
            <textarea
              type="text"
              name="message"
              rows="4"
              placeholder={T('Mensaje')}
              value={data?.message}
              onChange={onInputChange}
            />
            <input id="submit-button" type="submit" value={T('Enviar')} />
          </form>
          <img
            src={logo}
            alt="fidtail-logo"
            className="contact_container_right_logo"
          />
        </div>
      </div>
    </div>
  );
}

export default ContactView;
