import * as routes from '../../config/URL_ROUTES';

/** Import resources section **/

/*********************************************************************
 ************************  MENU ITEMS IDS  ***************************
 *********************************************************************/
const ABOUT_MENU = 'about';
const CERTIFICATES_MENU = 'certificates';
const CONTACT_MENU = 'contact';

/*********************************************************************
 **************************  MENU ITEMS  *****************************
 *********************************************************************/

const MENU_ITEMS = [
  {
    id: ABOUT_MENU,
    label: 'Quiénes somos',
    url: routes.ABOUT_ROUTE
  },
  {
    id: CERTIFICATES_MENU,
    label: 'Buscador mundial de detalladores certificados',
    url: routes.CERTIFICATES_ROUTE
  },

  {
    id: CONTACT_MENU,
    label: 'Contacto',
    url: routes.CONTACT_ROUTE
  }
];
export default MENU_ITEMS;
