/** Import react/libraries section **/
import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
/** Import resources section **/

/** Import helpers section **/

/** Import component section **/
import MenuItemView from './MenuItemView';

export const MenuItemComponent = ({ item }) => {
  let history = useHistory();
  let location = useLocation();
  const isSelected = location.pathname === item.url ? true : false;

  const handleOnClick = (e) => {
    history.push(item?.url);
  };

  return (
    <MenuItemView
      Icon={item?.icon}
      label={item?.label}
      isSelected={isSelected}
      onClick={item?.url ? handleOnClick : null}
    />
  );
};

export default MenuItemComponent;
