/*********************************************************************
 ***********************  ROUTES DEFINITION  *************************
 *********************************************************************/

// Public routes
export const ABOUT_ROUTE = '/';
export const CERTIFICATES_ROUTE = '/certificates';
export const CONTACT_ROUTE = '/contact';

// Private routes
