import React, { useState } from 'react';

import ContactView from './ContactView';

const ContactData = {
  name: '',
  email: '',
  message: ''
};

function ContactComponent() {
  const [data, setData] = useState(ContactData);

  const handleOnInputChange = (event) => {
    event.preventDefault();
    const target = event.target;
    const { name, value } = target;
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOnSubmit = (event) => {
    event.preventDefault();
    console.log(data);
  };

  return (
    <ContactView
      data={data}
      onInputChange={handleOnInputChange}
      onSubmit={handleOnSubmit}
    />
  );
}

export default ContactComponent;
