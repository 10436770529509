/** Import react/libraries section **/
import React from 'react';

/** Import resources section **/

/** Import styles section **/
import './MenuItemStyles.scss';

/** Import helpers section **/
import { T } from 'react-translator-component';

/** Import component section **/

export const MenuItemView = ({ Icon, label, isSelected, onClick }) => {
  return (
    <div className="menuitem">
      <div
        className={'menuitem_button' + (isSelected ? ' selected' : '')}
        onClick={onClick}
      >
        <span className="menuitem_button_label">{T(label)}</span>
      </div>
      <div className="menuitem_divisor" />
    </div>
  );
};

export default MenuItemView;
